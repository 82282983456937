import { useContext } from 'react';

import { ConfigContext } from '@providers/ConfigProvider';
import { FormattedMessage } from 'utils/intl';

import ShopBanner from '../ShopBanner';
import messages from './CommonBanner.messages';

const CommonBanner = (): JSX.Element => {
  const { contact } = useContext(ConfigContext);

  return (
    <ShopBanner
      images={['dell-partner', 'lenovo-partner', 'microsoft-partner']}
      header={<FormattedMessage {...messages.header} values={{ shop: contact.appShortName }} />}
      description={<FormattedMessage {...messages.description} />}
    />
  );
};

export default CommonBanner;
